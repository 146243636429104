import { NgClass, NgIf, NgTemplateOutlet } from '@angular/common';
import { Component, Inject, TemplateRef } from '@angular/core';
import { TribuuButtonDirective } from '@shared/directives/tribuu-button.directive';
import { TuiDialogContext } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';

@Component({
  selector: 'app-prompt',
  templateUrl: './prompt.component.html',
  standalone: true,
  imports: [TribuuButtonDirective, NgIf, NgTemplateOutlet, NgClass],
})
export class PromptComponent {
  data: IPromptData;

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT) private readonly context: TuiDialogContext<boolean, IPromptData>
  ) {
    this.data = context.data;
  }

  get contentTemplate() {
    return this.data.content instanceof TemplateRef ? this.data.content : null;
  }

  get textContentClass() {
    return this.data.centerContent || null;
  }

  get contentString() {
    return this.contentTemplate ? null : this.data.content;
  }
  get details() {
    return this.data.payload;
  }

  confirm() {
    this.context.completeWith(true);
  }
  cancel() {
    this.context.completeWith(false);
  }
}

export interface IPromptData {
  payload?: unknown;
  content: string | TemplateRef<any>;
  centerButtons?: boolean;
  theme?: 'primary' | 'secondary' | 'light' | 'dark' | 'danger';
  title: string;
  separator?: boolean;
  centerTitle?: boolean;
  textContentClass?: string;
  centerContent?: boolean;
  yes: string;
  no?: string;
}
