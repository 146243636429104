<div class="space-y-0.5 rounded-xl bg-white px-6 py-4">
  <h2 class="text-start text-xl font-bold text-gray-700" [ngClass]="{ '!text-center': data.centerTitle }">
    {{ data.title }}
  </h2>
  <img
    *ngIf="data.separator"
    src="/assets/images/default/form1.svg"
    alt="separator"
    class="mx-auto aspect-square h-12" />
  <p
    *ngIf="contentString"
    class="text-start text-sm font-medium text-neutral-500"
    [ngClass]="{ '!text-center': data.centerContent, textContentClass: !!textContentClass }">
    {{ contentString }}
  </p>
  <ng-container
    [ngTemplateOutlet]="contentTemplate"
    [ngTemplateOutletContext]="{ $implicit: details }"></ng-container>

  <div class="flex w-full justify-end gap-4 pt-6" [ngClass]="{ '!justify-center': data.centerButtons }">
    <button *ngIf="data.no" tribuuButton theme="light" size="sm" (click)="cancel()">
      {{ data.no }}
    </button>
    <button tribuuButton [theme]="data.theme ? data.theme : 'dark'" size="sm" (click)="confirm()">
      {{ data.yes }}
    </button>
  </div>
</div>
